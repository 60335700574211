import { RoleManagementContainerActions } from './actions';
import { roleManagementFeature } from './role-management.reducers';

const { reducer, name, ...roleManagementSelectors } = roleManagementFeature;

export {
  name as roleManagementName,
  reducer as roleManagementReducer,
  roleManagementSelectors,
  RoleManagementContainerActions,
};
