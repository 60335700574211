import { createFeature, createReducer, on } from '@ngrx/store';
import { RoleManagementState } from './role-management.state';
import { RoleManagementContainerActions } from './actions';

export const initialState: Readonly<RoleManagementState> = {
  roles: [],
  workerRoles: [],
  workers: [],
};

export const roleManagementReducers = createReducer(
  initialState,
  on(RoleManagementContainerActions.searchSuccess, (state, { data }) => ({
    ...state,
    workerRoles: data,
  })),
  on(RoleManagementContainerActions.getRolesSuccess, (state, { data }) => ({
    ...state,
    roles: data,
  })),
  on(RoleManagementContainerActions.getWorkersSuccess, (state, { data }) => ({
    ...state,
    workers: data,
  })),
);

export const roleManagementFeature = createFeature({
  name: 'roleManagement',
  reducer: roleManagementReducers,
});
