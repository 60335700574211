import { createAction, props } from '@ngrx/store';
import { Role, Worker, WorkerRole } from '../../../../core/models/app.models';

export const backClicked = createAction(
  '[Role Management Container] Back Clicked',
);
export const roleManagementInitialized = createAction(
  '[Role Management Container] Role Management initialized',
);
export const addClicked = createAction(
  '[Role Management Container] Add Clicked',
);
export const addRoleDone = createAction(
  '[Role Management Container] Add Role Done',
);
export const addRoleFailed = createAction(
  '[Role Management Container] Add Role Failed',
);
export const search = createAction(
  '[Role Management Container] Search',
  props<{ searchText: string }>(),
);
export const searchSuccess = createAction(
  '[Role Management Container] Search Success',
  props<{ data: WorkerRole[] }>(),
);
export const deleteClicked = createAction(
  '[Role Management Container] Delete Clicked',
  props<{ workerRole: WorkerRole }>(),
);
export const getRolesSuccess = createAction(
  '[Role Management Container] Get Roles Success',
  props<{ data: Role[] }>(),
);
export const getWorkersSuccess = createAction(
  '[Role Management Container] Get Workers Success',
  props<{ data: Worker[] }>(),
);
