import { Injectable } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';

import { validateChosenValue } from '../../core/helpers/validators';

@Injectable({
  providedIn: 'root',
})
export class RoleManagementService {
  public readonly form = this.fb.group({
    workerId: this.fb.control(0, [Validators.required, validateChosenValue]),
    role: this.fb.control(0, [Validators.required, validateChosenValue]),
  });

  public readonly searchForm = this.fb.group({
    search: this.fb.control('', []),
  });

  constructor(private fb: NonNullableFormBuilder) {}
}
